@import '../../style/variables';

.navbar {
  font-size: 12px;
  z-index: 100;
  margin-bottom: 30px;
  //margin-bottom: 60px;

  .wrapper {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;
    width: 38px;
    height: 12px;
    margin-right: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__top,
  &__bottom {
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__main-menu {
    &.menu {
      display: flex;
      justify-content: space-between;
      gap: 60px;
    }
  }

  &__top {
    background-color: $coal;
    z-index: 3;
    position: relative;
  }

  &__bottom {
    position: relative;
    background-color: $black;
  }

  &__sub-menu {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }

  &__notification {
    position: absolute;
    height: 52px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 100%;
    color: $coal;

    a {
      color: $link;
      margin-left: 5px;
    }
  }

  &__main-menu,
  &__sub-menu {
    .menu {
      &__item {
        display: flex;
        align-items: center;
        list-style: none;
        color: $grey;
        position: relative;

        .active {
          color: $white;
        }

        a,
        button {
          color: $grey;
          line-height: 14px;
          outline: none;

          &:hover {
            color: $white;
          }
        }
      }
    }

    .new {
      &:after {
        position: absolute;
        content: '';
        top: -5px;
        right: -5px;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 7.5px;
        background-color: $link;
        box-shadow: inset 0 1px 3px rgba(85, 84, 84, 0.5);
      }
    }
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes append-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
