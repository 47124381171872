@import '../../style/variables';

.profile {
  padding-bottom: 60px;

  .input {
    margin-bottom: 20px;
  }

  &__form {
    width: 400px;
    margin: 0 auto;

    .form {
      &__input-block {
        width: 360px;
        margin: 0 auto;
        margin-bottom: 65px;
      }

      &__textarea-block {
        .form__title {
          margin-left: 20px;
        }
      }

      &__editor {
        margin-bottom: 60px;
      }

      &__title {
        margin-bottom: 30px;
      }

      &__buttons {
        width: 210px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .saved {
          color: $dark-grey;
          width: 100px;
          display: flex;
          justify-content: center;
          font-size: 12px;
        }

        .loader {
          width: 100px;
          height: 30px;
        }
      }
    }
  }
}
