.assign-modal {
  height: 203px;

  &__select {
    margin-bottom: 30px;
  }

  &__btn {
    width: 100px;
    margin: 0 auto;
    text-transform: uppercase;
  }
}
