@import '../../../style/variables';

.accountscore {
  width: 800px;
  height: auto;
  //height: 570px;

  &_block {
    display: flex;
    justify-content: space-between;
  }

  &__col {
    width: 360px;
    font-weight: 400;
  }

  &__link {
    margin-top: 45px;

    a {
      outline: none;
      color: $link;

      &:hover {
        border-bottom: 1px solid $link;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    &.disabled > p {
      color: $medium-grey;
    }
  }

  &__text {
    &.green {
      color: $green;
    }

    &.red {
      color: $alert;
    }
  }
}
