@import '../../style/variables';

.customers__table {
  overflow: auto;
  padding-bottom: 30px;
  font-size: 14px;
  border-radius: 15px;
  height: 620px;
  border: 1px solid #f8f9f9;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-column-gap: 20px;
  background-color: $white;
  grid-template-columns:
        minmax(240px, 240px)
        minmax(100px, 100px)
        minmax(240px, 240px)
        minmax(100px, 100px)
        minmax(100px, 100px)
        minmax(100px, 100px)
        minmax(100px, 100px)
        minmax(80px, 80px);
  grid-template-rows: 40px;
  grid-auto-rows: 30px;

  thead,
  tbody,
  tr {
    display: contents;
  }

  td {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-align: end;
  }

  th {
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    top: 0;
    background-color: $white;
    text-align: end;
    padding-top: 12px;
    overflow: hidden;
    white-space: nowrap;
  }

  td,
  th {
    &:nth-child(1) {
      padding-left: 20px;
      text-align: left;
    }

    &:nth-child(3) {
      text-align: left;
    }
  }
}
