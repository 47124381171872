@import '../../style/variables';

.login {
  display: flex;
  flex-wrap: wrap;
  background-color: $bg;
  position: relative;
  min-height: calc(100vh - 30px);

  &__container {
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  &__content-wrap {
    padding-bottom: 140px;
  }

  &__central-block {
    width: 720px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    margin-bottom: 14px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
  }

  &__left-side {
    color: $coal;
  }

  &__form {
    margin-top: 35px;
    margin-right: 60px;

    &.form {
      width: 200px;

      .input {
        margin-bottom: 30px;
      }

      button {
        width: 100px;
        margin: 0 auto;
      }
    }
  }

  &__footer {
    height: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 90px;
    position: absolute;
    bottom: 70px;
    width: 100%;
  }

  &__loader-wrapper {
    height: 31px;
  }

  &__loader {
    display: block;
    margin: auto;
    width: 64px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    width: 38px;
    height: 12px;
    margin-bottom: 15px;
  }

  &__version {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
}
