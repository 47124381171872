//colours
$navy: #092c4c;
$medium-grey: #cfcfd1;
$blue: #167ffc;
$green: #27ae60;
$light-green: #ebfff7;
$pink: #fd3259;
$off-white: #fafafa;
$amber: #ffbf00;
$white: #ffffff;
$coal: #313132;
$dark-grey: #6e6e73;
$grey: #c8cacb;
$alert: #ff1513;
$bg: #f5f5f5;
$link: #0071e3;
$orange: #fc9c38;
$black: #000000;
$light-grey: #f8f9f9;
$deep-blue: #270ebf;
$aqua: #41ddfe;
$pink: #ff2dff;
$green: #1ebf1b;

//font-sizes
$title: 68px;
$dash-stat: 136px;
$subtitle: 34px;
$h1: 34px;
$h2: 21px;
$h3: 16px;
$p: 16px;
$description: 12px;
$button: 14px;
$field: 14px;
$chart-label: 10px;
$table-heading: 16px;
$table-title: 14px;
$table-content: 12px;
