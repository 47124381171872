@import '../../../style/variables';

.creditrisk {
  width: 800px;
  height: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  &_block {
    column-gap: 40px;
    font-weight: 400;
    column-count: 2;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;

    &.disabled > p {
      color: $medium-grey;
    }
  }

  &__text {
    &.green {
      color: $green;
    }

    &.red {
      color: $alert;
    }

    &.grey {
      color: $medium-grey;
    }
  }
}

//div::-webkit-scrollbar-thumb {
//  background-color: $medium-grey;
//  border: 4px solid transparent;
//  border-radius: 8px;
//  background-clip: padding-box;
//}
//
//div::-webkit-scrollbar {
//  width: 16px;
//}
