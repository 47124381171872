@import '../../style/variables';

.card-container {
  &__comment {
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: 500;
    font-size: 12px;
    color: $grey;
    text-transform: uppercase;
    display: inline-block;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    .card__text {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 15px;
    line-height: 39px;
  }

  &__info {
    line-height: 57px;

    &_small {
      font-weight: 400;
      font-size: 14px;
      margin: 0 10px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__edit-btn {
    position: absolute;
    right: 13px;
    bottom: 13px;
  }

  &__link {
    color: $link;
    display: block;
    margin-bottom: 10px;
  }
}

.dashboard__card {
  min-height: 262px;
}