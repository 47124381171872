@import '../../style/variables';

.custom-link {
  outline: none !important;
  color: $link;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid $link;
  }
}
