.comms-group {
  display: flex;
  flex-direction: column;

  label {
    margin-left: 0;
  }

  &__body {
    margin: 0 auto;
  }

  &__field {
    margin-bottom: 10px;
  }

  &__bottom-block {
    display: flex;
    padding-left: 20px;
    width: 400px;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
  }
}
