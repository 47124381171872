@import './style/variables';

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html,
body {
  height: auto;
}

body {
  overflow-x: hidden;
  background-color: #f5f5f5;
  min-height: 100dvh;
  margin: 0;
  padding: 0;
}

body.dark {
  background-color: $black;
  color: $white;

  h2 {
    color: $white;
  }

  .card {
    background-color: $coal;
  }

  & .title,
  .card-title {
    color: $white;
  }
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > .container {
  width: 1260px;
  margin: 0 auto;
  margin-top: 50px;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: $medium-grey;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;

  &:hover {
    background: #555;
  }
}

::-webkit-scrollbar {
  display: block;
  width: 2px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 15px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #C8CACB;
  border-right: none;
  border-left: none;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

