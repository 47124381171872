@import 'style/variables';
@import 'style/normalize';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  bottom: 0;
  margin: 0;
}

h1 {
  font-size: 40px;
  font-weight: 500;
}

h2 {
  font-size: 26px;
  font-weight: 500;
  color: $coal;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

span {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  border: none;
  background: none;
  color: $coal;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  color: $coal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: unset;
}

p {
  margin: 0;
  padding: 0;
}
