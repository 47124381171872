@import '../../style/variables';

.select {
  &__label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    display: inline-block;
  }
}
