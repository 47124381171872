@import '../../style/variables';

.button {
  border-radius: 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  border: 1px solid $grey;
  background-color: $white;
  cursor: pointer;
  font-weight: 400;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  width: 100px;

  &:disabled {
    color: $grey;
    cursor: unset;
    box-shadow: none;

    &:hover {
      background-color: $white;
    }
  }

  &:hover,
  &:active {
    &:not([disabled]) {
      background-color: $dark-grey;
      color: $white;
    }
  }

  &:active {
    &:not([disabled]) {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
