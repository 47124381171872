@import '../../../style/variables';

.events {
  &__table-container {
    margin: 0 auto;
    width: 830px;
    height: 614px;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 15px;
    background-color: $white;
  }

  &__table {
    &.table {
      padding-left: 20px;
      display: grid;
      overflow: auto;
      border: 1px solid #f8f9f9;
      font-size: 14px;
      padding-bottom: 30px;
      border-radius: 15px;
      border-collapse: collapse;
      min-width: 100%;
      min-height: 100%;
      grid-column-gap: 20px;
      grid-template-columns:
                minmax(295px, 295px)
                minmax(50px, 50px)
                minmax(150px, 150px)
                minmax(50px, 50px)
                minmax(140px, 140px);
      grid-template-rows: 40px;
      grid-auto-rows: 30px;

      thead,
      tbody,
      tr {
        display: contents;
      }

      td {
        overflow: hidden;
        white-space: nowrap;
      }

      th,
      td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: end;
        }
      }

      th {
        font-weight: 500;
        text-align: left;
        position: sticky;
        padding-top: 12px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }


  &__modal {
    &_sms {
      width: 400px;
      height: fit-content;
      max-height: 666px;
      overflow: auto;

      .modal {
        &__top {
          margin-bottom: 30px;
        }

        &__item {
          margin-bottom: 5px;
        }

        &__name {
          display: inline-block;
          font-weight: 500;
          min-width: 60px;
          margin-right: 5px;
        }

        &__body {
          p {
            margin-bottom: 5px;
          }
        }
      }
    }

    &_email {
      width: 800px;
      max-height: 666px;
      min-height: 500px;
      overflow: auto;

      .modal {
        &__attachment {
          text-align: center;
          color: $coal;
          margin-right: 10px;
        }

        &__attachments {
          display: flex;
        }

        &__filename {
          margin-top: 10px;
          font-size: 12px;
        }

        &__text {
          margin-bottom: 30px;
        }

        &__top {
          margin-bottom: 30px;
        }

        &__item {
          margin-bottom: 5px;
        }

        &__name {
          display: inline-block;
          font-weight: 500;
          min-width: 60px;
          margin-right: 5px;
        }

        &__body {
          p {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  button {
    outline: none !important;
    color: $link;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $link;
    }
  }
}