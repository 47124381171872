.loan-modal {
  position: relative;

  &__select {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 30px;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  &__details-block {
    .details {
      &__item {
        margin-bottom: 10px;
      }
    }

    margin-bottom: 30px;
  }

  &__reason {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    margin-bottom: 50px;
    height: 125px;
    padding: 0 20px;
    width: 334px;
    margin-left: auto;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 90%;
      border-right: 1px solid #c8cacb;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 97%;
      border-bottom: 1px solid #c8cacb;
    }
  }

  &__fields-block {
    margin-bottom: 20px;
  }

  &__selector {
    position: absolute;
    top: 20px;
    right: 20px;

    > button {
      margin-right: 10px;
      color: #0071e3;

      &.active {
        color: #c8cacb;
      }
    }
  }

  &__quote {
    position: relative;

    &:before {
      content: '';
      background: url('../../../assets/quote.png') no-repeat;
      height: 30px;
      width: 21px;
      left: -45px;
      top: 15px;
      position: absolute;
    }
  }

  &__btn-group {
    margin: 0 auto;
    width: 210px;
  }
}
