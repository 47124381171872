.customers-search {
  margin-top: 30px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 300px);
  }
}
