@import '../../style/variables';

.card {
  width: 100%;
  height: 100%;
  padding: 20px 20px 30px 20px;
  background-color: $white;
  font-size: 14px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);

  &.dark-grey {
    background-color: $dark-grey;
  }

  &.grey {
    background-color: $grey;
  }
}

.lg {
  border-radius: 15px;
  width: 400px;
}

.sm {
  border-radius: 7.5px;
  width: 185px;
  height: 135px;
}

.full {
  border-radius: 15px;
  width: 100%;
}

.light {
  background-color: $white;
}

.dark {
  background-color: $coal;
}

.disabled {
  color: $medium-grey;

  .card__value,
  .card__info {
    color: transparent;
  }
}

.clickable {
  cursor: pointer;
}
